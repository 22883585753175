<template>
  <div class="row">
    <div class="col-12" style="margin-bottom: 1%">
      <div class="row">
        <div class="col text-left d-flex  align-items-center ">
          <button class="btn  btn-info rounded-pill  btn-sm ntn-md SN-btn" style="cursor: copy;" @click="copyToClipboard">
            <span class="d-inline-block mr-2">{{
            0 > this.$store.getters.sn ? $t('SubNavBar.Overview') : "SN :"
          }}</span>
            <strong>{{
              this.$store.getters.sn == -1 ? "" : this.$store.getters.sn
            }}</strong>
          </button>
        </div>
        <div class="col d-flex justify-content-center  text-center ">
          <img class="img-fluid logo_sax_login" style="max-width: 100%; height: auto;" src="img/saxlogo.png"
            alt="Logo" />
        </div>
        <div class="col text-right d-flex align-items-center justify-content-end phases">
          <div v-if="Object.keys(sns).length > 1">


            <div class="btn-group btn-group-toggle " data-toggle="buttons">
              <div v-for="(sn, index) in sns" :key="index">
                <Serialnumber class="btn p-1 py-1" :sn="index + 1"
                  :label="`<span class='phase_text'>Phase </span><span class='letter'>${phases[sn.phase - 1]}</span>`"
                  :is-active="sn.isActive" @reset-options="resetSelectedOptions" />
              </div>
            </div>













          </div>


        </div>
      </div>
    </div>
    <!-- Stats Cards -->
    <div class="col-lg col-md-6" style="height:7rem;" v-for="(card, index) in updatedStatsCards" :key="index">
      <stats-card :title="card.title" :sub-title="card.subTitle" :type="card.type" :icon="card.icon"></stats-card>
    </div>
    <!-- Big Chart -->
    <div class="col-12">
      <div class="row">
        <div class="col-lg-8 col-md col-sm col" style="margin-bottom: 30px">

          <card type="chart" class="d-flex h-100 flex-column">
            <Chart />

          </card>
        </div>
        <div class="col-lg-4 col-md col-sm col status" style="margin-bottom: 30px">
          <div class="card h-100">
            <div class="card-header">
              <div class="col-12 p-lg-4" style=" padding-top: 0 !important;">
                <div>
                  <SvgComponent ref="svgComponent" />{{ refresh }}
                </div>
                <div class="row">
                  <div class="col-4 col-md-4 d-flex justify-content-center align-items-center">
                    <div v-if="!isTotalSelected">
                      <div v-if="typeof isOnline !== 'undefined' && isOnline">
                        <p style="font-size:calc(1rem + 1vw); ;color: #42B883 !important; "
                          class=" font-weight-bold mb-0">
                          {{ "Online" }}
                        </p>
                      </div>
                      <div v-else>
                        <p style="font-size:calc(1rem + 1vw);color: #F4AB2A !important;"
                          class="font-weight-bold card-title">
                          {{ "Offline" }}
                        </p>

                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-md-4 d-flex justify-content-center align-items-center" style="height: 50px;">

                  </div>

                  <div class="col-4 col-md-4 d-flex justify-content-center align-items-center card-text">
                    <div style="font-size:calc(1rem + 0.5vw);">
                      <div v-if="batteryStatus === 4">
                        <strong class="text-uppercase " style="color: #285891;">standby</strong>
                      </div>
                      <div v-if="batteryStatus === 3">
                        <strong class="text-uppercase  " style="color: #285891;">{{ $t('status.connected') }}</strong>
                      </div>
                      <div v-if="batteryStatus === 2">
                        <strong class="text-uppercase " style="color: #285891;">{{ $t('status.on') }}</strong>
                      </div>
                      <div v-if="batteryStatus === 1">
                        <strong class="text-uppercase " style="color: #285891;">{{ $t('status.off') }}</strong>
                      </div>
                      <div v-if="!batteryStatus">
                        <strong class="text-uppercase "><br></strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="height: 100;">

              <div class="card" style="box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); margin-bottom: 0px">
                <div class="card-body d-flex flex-column px-2 px-lg-4">
                  <strong class=" d-flex justify-content-center pb-3" style="font-size: 1em; color: #285891;">{{
            $t('oneItem.Messages') }}</strong>
                  <ul v-if="lastMessages.length" style="list-style: none; padding: 0;">
                    <li v-for="letzteMeldung in lastMessages.slice(0, 4)" :key="letzteMeldung"
                      style="display: inline-block; margin-right: 10px;">
                      <small style="font-size: 1em;">
                        {{ letzteMeldung }}
                      </small>
                    </li>
                  </ul>
                  <ul v-if="!lastMessages.length">
                    <li>
                      <small style="font-size: 1em;">
                        {{ $t('oneItem.NoMessages') }}
                      </small>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>





    <!-- Small charts -->
    <div class="col-lg-4">
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-category">{{ $t('StateCards.GridPower') }} </h5>
            <select id="daySel_m2" class="form-select rounded" aria-label="Default select example"
              v-model="selectedOption" @change="handleSelection({ m2: true, m4: false, m5: false })">
              <option value="1">7 {{ $t('oneItem.days') }}</option>
              <option value="2">30 {{ $t('oneItem.days') }}</option>

            </select>
          </div>

          <h3 class="card-title">
            <i style="font-size:1.2rem;" class="mdi mdi-transmission-tower mdi-36px text-primary"></i>({{ average_m2 }}
            W)
            AVG
          </h3>
        </template>
        <div class="chart-area">
          <MyChart :lineData="get_data_m2" :labelData="get_m2_label" :areaColor=BackgroundAreaColor
            :LineBorderColor=useLineAndBoarderColor :toolTipeLabel="$t('StateCards.GridPower')"
            :scaleLabelName="`${$t('StateCards.GridPower')} [W]`" />
        </div>
      </card>
    </div>
    <div class="col-lg-4">
      <card class="card-chart">
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-category">{{ $t('StateCards.PV_power') }}</h5>
            <select id="daySel_m4" class="rounded form-select" aria-label="Default select example"
              v-model="selectedOptionM" @change="handleSelectionM({ m2: false, m4: true, m5: false })">
              <option value="1">7 {{ $t('oneItem.days') }}</option>
              <option value="2">30 {{ $t('oneItem.days') }}</option>

            </select>

          </div>

          <h3 class="card-title">
            <i class="mdi mdi-solar-power-variant-outline mdi-36px text-primary"></i>({{ average_m4 }} W)
          </h3>
        </template>
        <div class="chart-area">
          <MyChart :lineData="get_data_m4" :labelData="get_m4_label" :areaColor=sBackgroundAreaColor
            :LineBorderColor=sUseLineAndBoarderColor :toolTipeLabel="$t('StateCards.PV_power')"
            :scaleLabelName="`${$t('StateCards.PV_power')} [W]`" />

        </div>
      </card>
    </div>
    <div class="col-lg-4">
      <card class="card-chart">
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-category">{{ this.$t('StateCards.Batterypower') }}</h5>
            <select id="daySel_m5" class="rounded form-select" aria-label="Default select example"
              v-model="selectedOptionMM" @change="handleSelectionMM({ m2: false, m4: false, m5: true })">
              <option value="1">7 {{ $t('oneItem.days') }}</option>
              <option value="2">30 {{ $t('oneItem.days') }}</option>

            </select>
          </div>

          <h3 class="card-title">
            <i class="mdi mdi-car-battery mdi-36px text-primary"></i>({{ average_m5 }} W) AVG
          </h3>
        </template>
        <div class="chart-area">
          <MyChart :lineData="get_data_m5" :labelData="get_m5_label" :areaColor=BackgroundAreaColor
            :LineBorderColor=useLineAndBoarderColor :toolTipeLabel="$t('StateCards.Batterypower')"
            :scaleLabelName="`${$t('StateCards.Batterypower')} [W]`" />

        </div>
      </card>
    </div>
    <!-- <div class="col-lg-3 col-sm col-md">
      <div class="card h-100 d-flex align-items-center justify-content-center ">

        <Battary :batteryPercentage="m6AsInteger" />
      </div>

    </div> -->
    <div class="col-lg-4 col-sm col-md">
      <card class="card-chart h-100">
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-category">{{ $t('oneItem.SOC') }}</h5>
            <select id="daySel_m" class="rounded form-select" aria-label="Default select example"
              v-model="selectedOption_soc" @change="handleSelection_SOC()">
              <option value="1">7 {{ $t('oneItem.days') }}</option>
              <option value="2">30 {{ $t('oneItem.days') }}</option>

            </select>
          </div>

          <h3 class="card-title">
            <i :class="`mdi mdi-battery-charging-${Math.round(average_m6 / 10) * 10} mdi-36px text-primary`"></i>({{
            average_m6 }} %) AVG
          </h3>
        </template>
        <div class="chart-area">
          <MyChart :lineData="get_data_m6" :labelData="get_m6_label" :areaColor=BackgroundAreaColor
            :LineBorderColor=useLineAndBoarderColor :toolTipeLabel="'SOC'" :scaleLabelName="`SOC [%]`" />
        </div>
      </card>



    </div>
    <div class="col-lg-5 col-sm col-md">
      <card class="h-100 lastM">
        <template slot="header">
          <h4 style="font-size: 2em;" class=" text-center">{{ $t('oneItem.Message') }}</h4>
        </template>
        <div class="card-body">
          <div v-if="this.$store.getters.sn != -1">
            <div class="table-responsive" style="height: 200px; overflow-y: auto;">
              <table class="table " style="width: 100%">
                <thead>
                  <th style="width: 60%;">{{ $t('oneItem.NA-Schutz') }}</th>
                  <th style="width: 40%;">{{ $t('oneItem.Date') }}</th>
                </thead>
                <tbody>
                  <tr v-for="(lastMessage, i) in lastMessagess" :key="i">
                    <td style="width: 40%;">
                      <div style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        {{ isValidDate(lastMessage.time) ? convertMeldung(lastMessage.message) : '' }}
                      </div>
                    </td>
                    <td style="width: 60%;">
                      <div style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        {{ isValidDate(lastMessage.time) ? convertDate(lastMessage.time) : '' }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <div>
              <h5 style="font-size: 2em;padding-top:1em" class="text-uppercase d-flex justify-content-center">
                {{ $t('oneItem.NA-Schutz') }}
              </h5>
            </div>
            <p style="font-size: 2em; padding-top:2.5em; text-align: center;" class=" d-flex justify-content-center ">{{
            $t('oneItem.tablenomessage') }}</p>
          </div>
        </div>
      </card>
    </div>




  </div>
</template>
<script>





import Chart from "@/components/Linechart/Chart.vue";
import MyChart from '@/components/Linechart/chart/MyChart.vue';
import Battary from "@/components/Linechart/Battary.vue";

import BarChart from 'src/components/Charts/BarChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';


import StatsCard from "src/components/Cards/StatsCard";
import { store } from "@/store";
import Serialnumber from "@/components/Linechart/Serialnumber.vue";
import { saxMaps } from "@/modules/maps";

import SvgComponent from 'src/assets/images/dot.svg';






export default {
  components: {
    Chart,
    Serialnumber,
    StatsCard,
    MyChart,
    BarChart,
    Battary,
    SvgComponent

  },
  data() {
    return {

      showbatt_house: false,
      showpv_batt: false,
      showgrid_house: false,
      showgrid_batt: false,
      showpv_house: false,
      showpv_grid: false,
      showSin: false,



      showgrid_N: false,
      showgrid_P: false,
      showcons: false,
      showpv_P: false,
      showbatt_P: false,
      showbatt_N: false,

      showblue: false,
      showalert: false,
      showstandby: false,

      barChart1: {
        chartData: {
          labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC', 'JAN'],
          datasets: [
            {
              label: 'Data',
              fill: true,
              borderColor: '#42b883',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [80, 100, 70, 80, 120, 80, 100]
            }
          ]
        },
        extraOptions: chartConfigs.barChartOptionsGradient,
        gradientColors: [
          'rgba(76, 211, 150, 0.1)',
          'rgba(53, 183, 125, 0)',
          'rgba(119,52,169,0)'
        ],
        gradientStops: [1, 0]
      },
      hasNoMessage: true,
      selectedOption: 1,
      selectedOptionM: 1,
      selectedOptionMM: 1,
      selectedOption_soc: 1,

      useLineAndBoarderColor: '#42b883',
      BackgroundAreaColor: [
        'rgba(76, 211, 150, 0.1)',
        'rgba(53, 183, 125, 0)',
        'rgba(119,52,169,0)'
      ],

      sUseLineAndBoarderColor: '#285891',
      sBackgroundAreaColor: [
        'rgba(90, 123, 255, 0.1)',
        'rgba(53, 183, 125, 0)',
        'rgba(119, 52, 169, 0)'
      ],



      darkMode: this.$store.getters.getDarkMode,
      selectedSN: null,
      dataInterval: undefined,
      isLoading: false,
      isInitializing: true,
      date: new Date(),
      sns: this.$store.getters.sns ? this.$store.getters.sns : {},
      phases: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      activeIndex: 0,
      timeNow: undefined,




    };
  },
  created() {

    this.$store.dispatch("get_soc_chart_data", {
      sn: store.getters.sn,
      days: 7,
    });

    this.$store.dispatch("get_apex_chart_data", {
      sn: store.getters.sn,
      m2: true,
      m4: true,
      m5: true,
      days: 7,

    });
    this.$store.dispatch('checkSnUpdate');
    this.sendParamsAndGetData();
    this.$store.dispatch("get_current_data");
    this.dataInterval = setInterval(() => {




      try {
        this.isLoading = true;
        this.$store.dispatch("get_current_data");

        try {
          if (this.$store.getters.getAccessStatus === 401) {
            this.$store.dispatch("getNewAccessToken");
          }
        }
        catch (error) {
          this.$router.push('/login');

        }


      } catch (error) {




      }

      this.isLoading = false;
    }, 3000);
  },
  computed: {
    refresh() {
      this.showbatt_house = false;
      this.showpv_batt = false;
      this.showgrid_house = false;
      this.showgrid_batt = false;
      this.showpv_house = false;
      this.showpv_grid = false;
      this.showSin = false;

      this.showgrid_N = false,
        this.showgrid_P = false,
        this.showcons = false,
        this.showpv_P = false,
        this.showbatt_P = false,
        this.showbatt_N = false,

        this.showblue = false;
      this.showalert = false;
      this.showstandby = false;

      console.log("m2: ", this.m2, " m5: ", this.m5, " m4: ", this.m4)

      // if (this.m2 === 0 || this.m5 === 0) {
      //   if (this.m4 === 0 || this.m4 < 0) this.showpv_house = false;
      //   else this.showpv_house = true;

      //   if (this.m2 === 0) this.showgrid_house = false;
      //   else if (this.m2 > 0) this.showgrid_house = true;
      //   else this.showpv_grid = true;

      //   if (this.m5 === 0) this.showbatt_house = false;
      //   else if (this.m5 > 0) this.showbatt_house = true;
      //   else this.showpv_batt = true;

      // }
      // if (this.m2 > 0 && this.m5 < 0) {
      //   this.showgrid_batt = true; this.showgrid_house = true;

      // }
      // if (this.m2 > 0 && this.m5 > 0) {
      //   this.showbatt_house = true; this.showgrid_house = true;

      // }
      // if (this.m2 < 0 && this.m5 < 0) {
      //   this.showpv_grid = true; this.showpv_batt = true; this.showpv_house = true;

      // }
      // if (this.m2 < 0 && this.m5 > 0) {
      //   this.showpv_grid = true; this.showbatt_house = true; this.showpv_house = true;

      // }

      // if (this.m4 > 0 || (this.m2 < 0 && this.m5 <= 0) || (this.m2 <= 0 && this.m5 < 0)) this.showpv_P = true;
      this.showpv_P = this.showgrid_P = this.showbatt_P = this.showgrid_N = this.showbatt_N = this.showcons = this.showblue = false;
      if (!this.isOnline && this.$store.getters.sn != -1) {
      }
      else {
        if (this.m4 > 0) this.showpv_P = true;
        if (this.m4 != 0 || this.m2 != 0 || this.m5 != 0) this.showcons = true;

        if (this.m2 === 0) {
          
        }

        if (this.m5 === 0) {
        
        }

        if ((this.m2 > 0 && this.m5 < 0) || (this.m2 < 0 && this.m5 > 0)) {
          // console.log("(this.m2 > 0 && this.m5 < 0) || (this.m2 < 0 && this.m5 > 0)")
          if (Math.abs(this.m2) > Math.abs(this.m5)) {
            // console.log("Math.abs(this.m2):", Math.abs(this.m2),">", Math.abs(this.m5),"Math.abs(this.m5)")
            if (this.m2 > 0) {
              this.showgrid_P = true;
              if (this.m5 >= 30) this.showbatt_P = true;
            }
            else if (this.m2 < 0) {
              // console.log("this.m2) neg")
              this.showgrid_N = true;
              if (this.m5 <= -30) this.showbatt_N = true;
              this.showpv_P = true;
            }
          }
          else if (Math.abs(this.m2) < Math.abs(this.m5)) {
            // console.log("Math.abs(this.m2):", Math.abs(this.m2),"<", Math.abs(this.m5),"Math.abs(this.m5)")
            if (this.m5 > 0) {
              this.showgrid_P = true;
              if (this.m5 >= 30) this.showbatt_P = true;
            }
            else if (this.m5 < 0) {
              this.showgrid_N = true;
              this.showpv_P = true;
              if (this.m5 <= -30) this.showbatt_N = true;
            }
          }
          else if (Math.abs(this.m2) === Math.abs(this.m5)) {
            // console.log("Math.abs(this.m2):", Math.abs(this.m2),"=", Math.abs(this.m5),"Math.abs(this.m5)")
            if (this.m2 > 0) {
              // console.log("this.m2) pos")
              this.showgrid_P = true;
              if (this.m5 >= 30) this.showbatt_P = true;
            }
            else if (this.m2 < 0) {
              this.showgrid_N = true;
              this.showpv_P = true;
              if (this.m5 <= -30) this.showbatt_N = true;
              else if (this.m5 >= 30) this.showbatt_P = true;
            }
          }
        }
        else if (this.m2 > 0 && this.m5 > 0) {
          this.showgrid_P = true;
          if (this.m5 >= 30) this.showbatt_P = true;
        }
        else if (this.m2 < 0 && this.m5 < 0) {
          // console.log("this.m2 < 0 && this.m5 < 0")
          this.showgrid_N = true;
          this.showpv_P = true;
          if (this.m5 <= -30) this.showbatt_N = true;
        }
        else if (this.m5 > 30) { this.showbatt_P = true; }
        else if (this.m5 < -30) { this.showbatt_N = true; this.showpv_P = true;}
        else if (this.m2 > 0) { this.showgrid_P = true; }
        else if (this.m2 < 0) { this.showgrid_N = true; this.showpv_P = true;}


        if (!this.lastMessages.length) this.showblue = true;
        if (this.batteryStatus === 4) {
          this.showstandby = true;
        }
        if (this.lastMessages.length) {
          this.showalert = true;
        }

      }
      return this.updateSvgVisibility()


    },
    updatedStatsCards() {
      const soc = Math.floor(this.m6 / 10) * 10;

      let batteryIcon;
      if (this.m6 > 0) batteryIcon = soc === 0 ? 'mdi mdi-battery-charging-10' : `mdi mdi-battery-charging-${soc}`;
      else batteryIcon = soc === 0 ? 'mdi mdi-battery-charging-outline' : `mdi mdi-battery-charging-${soc}`;

      let grid_power;
      if (this.m2 < 0) grid_power = "mdi mdi-transmission-tower-import";
      else if (this.m2 == 0) grid_power = "mdi mdi-transmission-tower";
      else grid_power = "mdi mdi-transmission-tower-export";

      const updatedStatsCards = [
        {
          title: `${this.m6.toString()}%`,
          subTitle: this.$t('StateCards.SOC'),
          type: "info",
          icon: batteryIcon,
        },
        {
          title: `${this.m5.toString()} W`,
          subTitle: this.$t('StateCards.Batterypower'),
          type: "success",
          icon: "mdi mdi-car-battery",
        },
        {
          title: `${this.m4.toString()} W`,
          subTitle: this.$t('StateCards.PV_power'),
          type: "info",
          icon: "mdi mdi-solar-power-variant-outline",
        },
        // {
        //   title: `${this.m1.toString()} V`,
        //   subTitle: this.$t('StateCards.Gridvoltage'),
        //   type: "success",
        //   icon: "mdi mdi-lightning-bolt",
        // },
        {
          title: `${this.m2.toString()} W`,
          subTitle: this.$t('StateCards.GridPower'),
          type: "success",
          icon: grid_power,
        },



      ];

      return updatedStatsCards;
    },
    m6AsInteger() {

      return parseInt(this.m6, 10);
    },
    lastMessagess() {

      store.getters.lastMessages[store.getters.sn]?.last_messages?.last_messages?.map(
        (message) => {
          if (message.time !== "2000-0-0 0:0:0.0") this.hasNoMessage = false;
        }
      );

      return store.getters.lastMessages[store.getters.sn]?.last_messages?.last_messages || [{ time: '2000-0-0 0:0:0.0' }];


    },
    lang() {
      return this.$i18n.locale;
    },
    timeZone() {
      return store.getters.timeZone;
    },









    isOnline: () => {
      try {


        if (
          typeof store.getters.lastOnlineFrom[store.getters.sn]
            .last_online_from == "undefined"
        ) {
          return false;
        } else {
          return (
            store.getters.lastOnlineFrom[store.getters.sn].last_online_from <
            600 || store.getters.updateStarted[store.getters.sn].update_started
          );
        }
      } catch (error) {
        console.error("Navbar(Isonline) Error isOnline has no value will 0 be returned");
        return 0;
      }

      //return 1;
    },
    isTotalSelected: () => {
      return store.getters.sn == -1;
    },
    lastMessages() {
      if (store.getters.sn == -1) return [];
      else {
        try {
          store.commit(
            "setMessage2",
            store.getters.allMessage2[store.getters.sn].message2
          );

          return store.getters.message2;
        } catch (error) {
          console.error(
            "General(lastmessages) lastmessages has error and will be returned as []"
          );
          return [];
        }
      }
    },

    areThereWarnings() {
      let hasAlarm = 0;

      this.lastMessages.map((el) => {
        if (el != i18n.tc("warten60")) hasAlarm += 1;
      });

      return hasAlarm;
    },
    batteryStatus() {
      try {
        if (store.getters.sn == -1) return null;
        else return store.getters.batteryStatus[store.getters.sn].batteryStatus;
      } catch (error) {
        return 1;
      }
    },
    get_m2_label: () => {
      return store.getters.getChatLabel_m2;
    },
    get_m4_label: () => {
      return store.getters.getChatLabel_m4;
    },
    get_m5_label() {
      return store.getters.getChatLabel_m5;
    },
    get_m6_label() {
      return store.getters.getChatLabel_m6;
    },
    get_data_m2: () => {
      return store.getters.getChatData_m2;
    },
    average_m2: () => {
      const chatData_m2 = store.getters.getChatData_m2;

      if (!chatData_m2 || chatData_m2.length === 0) {
        return 0;
      }

      const sum = chatData_m2.reduce((acc, curr) => acc + curr, 0);
      return Math.round(sum / chatData_m2.length);
    },
    average_m4: () => {
      const chatData_m4 = store.getters.getChatData_m4;

      if (!chatData_m4 || chatData_m4.length === 0) {
        return 0;
      }

      const sum = chatData_m4.reduce((acc, curr) => acc + curr, 0);
      return Math.round(sum);
    },
    average_m5: () => {
      const chatData_m5 = store.getters.getChatData_m5;

      if (!chatData_m5 || chatData_m5.length === 0) {
        return 0;
      }

      const sum = chatData_m5.reduce((acc, curr) => acc + curr, 0);
      return Math.round(sum / chatData_m5.length);
    },
    average_m6: () => {
      const chatData_m6 = store.getters.getChatData_m6;

      if (!chatData_m6 || chatData_m6.length === 0) {
        return 0;
      }

      const sum = chatData_m6.reduce((acc, curr) => acc + curr, 0);
      return Math.round(sum / chatData_m6.length);
    },
    get_data_m4: () => {
      return store.getters.getChatData_m4;
    },
    get_data_m5() {
      return store.getters.getChatData_m5;
    },
    get_data_m6() {
      return store.getters.getChatData_m6;
    },

    m1: () => {
      try {
        if (store.getters.sn == -1) {
          let temp = 0;

          Object.keys(store.getters.grid_voltage).map((sn) => {
            temp = temp + store.getters.grid_voltage[sn].grid_voltage;
          });

          temp = temp / (store.getters.sns.length - 1); //Average value
          return Number(temp).toFixed(0); //.toFixed(2); showing 2 decimal places
        } else {
          return store.getters.grid_voltage[store.getters.sn].grid_voltage;
        }
      } catch (error) {
        return "-";
      }
    },
    m2: () => {
      try {
        if (store.getters.sn == -1) {
          //return store.getters.grid_power[store.getters.sns[0].sn].grid_power;
          let temp = 0;

          Object.keys(store.getters.grid_power).map((sn) => {
            temp = temp + store.getters.grid_power[sn].grid_power;
          });

          temp = temp / (store.getters.sns.length - 1);
          return Number(temp).toFixed(0); //.toFixed(2); showing 2 decimal places
        } else return store.getters.grid_power[store.getters.sn].grid_power;
      } catch (error) {
        return "-";
      }
    },
    m3: () => {
      try {
        if (store.getters.sn == -1) {
          return store.getters.discharge_energy[store.getters.sns[0].sn]
            .discharge_energy;
        } else
          return store.getters.discharge_energy[store.getters.sn]
            .discharge_energy;
      } catch (error) {
        return "_";
      }
    },
    m4: () => {
      try {
        if (store.getters.sn == -1) {
          return store.getters.PV_power[store.getters.sns[0].sn].PV_power;
        } else return store.getters.PV_power[store.getters.sn].PV_power;
      } catch (error) {
        return "-";
      }
    },
    m5: () => {
      try {
        if (store.getters.sn == -1) {
          let temp = 0;

          Object.keys(store.getters.battery_power).map((sn) => {
            temp = temp + store.getters.battery_power[sn].battery_power;
          });

          //temp = temp / (store.getters.sns.length - 1); Average value
          return Number(temp).toFixed(0); //.toFixed(2); showing 2 decimal places
        } else
          return store.getters.battery_power[store.getters.sn].battery_power;
      } catch (error) {
        return "-";
      }
    },

    m6: () => {
      try {
        if (store.getters.sn == -1) {
          let temp = 0;

          Object.keys(store.getters.SOC).map((sn) => {
            temp = temp + store.getters.SOC[sn].SOC;
          });

          temp = temp / (store.getters.sns.length - 1);
          return Number(temp).toFixed(0); //.toFixed(2); showing 2 decimal places
        } else return store.getters.SOC[store.getters.sn].SOC;
      } catch (error) {
        return "-";
      }
    },



  },
  methods: {
    updateSvgVisibility() {
      const svgElements = document.getElementsByTagName('svg');
      if (svgElements.length > 0) {
        const svgElement = svgElements[1]; // Assuming it's the first SVG
        const batt_house = svgElement.getElementById('batt_house');
        const pv_batt = svgElement.getElementById('pv_batt');
        const grid_house = svgElement.getElementById('grid_house');
        const grid_batt = svgElement.getElementById('grid_batt');
        const pv_house = svgElement.getElementById('pv_house');
        const pv_grid = svgElement.getElementById('pv_grid');
        const Sin = svgElement.getElementById('Sin');


        const grid_N = svgElement.getElementById('grid_N');
        const grid_P = svgElement.getElementById('grid_P');
        const cons = svgElement.getElementById('cons');
        const pv_P = svgElement.getElementById('pv_P');
        const batt_P = svgElement.getElementById('batt_P');
        const batt_N = svgElement.getElementById('batt_N');

        const blue = svgElement.getElementById('blue');
        const alert = svgElement.getElementById('alert');
        const standby = svgElement.getElementById('standby');

        batt_house.style.visibility = this.showbatt_house ? '' : 'hidden';
        pv_batt.style.visibility = this.showpv_batt ? '' : 'hidden';
        grid_house.style.visibility = this.showgrid_house ? '' : 'hidden';
        grid_batt.style.visibility = this.showgrid_batt ? '' : 'hidden';
        pv_house.style.visibility = this.showpv_house ? '' : 'hidden';
        pv_grid.style.visibility = this.showpv_grid ? '' : 'hidden';
        Sin.style.visibility = this.showSin ? '' : 'hidden';


        grid_N.style.visibility = this.showgrid_N ? '' : 'hidden';
        grid_P.style.visibility = this.showgrid_P ? '' : 'hidden';
        cons.style.visibility = this.showcons ? '' : 'hidden';
        pv_P.style.visibility = this.showpv_P ? '' : 'hidden';
        batt_P.style.visibility = this.showbatt_P ? '' : 'hidden';
        batt_N.style.visibility = this.showbatt_N ? '' : 'hidden';

        blue.style.visibility = this.showblue ? '' : 'hidden';
        alert.style.visibility = this.showalert ? '' : 'hidden';
        standby.style.visibility = this.showstandby ? '' : 'hidden';

      }

    },


    convertMeldung(msg) {
      return saxMaps.getMeldung(msg);
    },
    convertOnOff(msg) {
      return saxMaps.getOnOff(msg);
    },
    convertDate(date) {
      if (date == "") return "-";
      // return this.$i18n.d(this.$dayjs(date).utcOffset(this.$dayjs.utcOffset).$d, 'year_time')
      return this.$i18n.d(this.$dayjs(date), "year_time");
    },


    isValidDate(dateString) {
      try {
        if (!dateString) {
          return false;
        }
        const date = new Date(dateString);
        return !isNaN(date.getTime());
      } catch (error) {
        console.error("General(isValidDate) isValidDate has error and will return false", error);
        return false;
      }

    },



    resetSelectedOptions() {
      this.selectedOption = 1;
      this.selectedOptionM = 1;
      this.selectedOptionMM = 1;
      this.selectedOption_soc = 1;
    },
    handleSelection(options) {

      const { m2, m4, m5 } = options;
      let number = this.selectedOption === '1' ? 7 : 30;
      let parames = {
        sn: store.getters.sn,
        m2: m2,
        m4: m4,
        m5: m5,
        days: number,
      };
      if (store.getters.sn != -1) {
        this.$store.dispatch("get_apex_chart_data", parames);

      } else {
        this.$store.dispatch("get_apex_chart_data_overview", parames);

      }


    },
    handleSelection_SOC() {
      let number = this.selectedOption_soc === '1' ? 7 : 30;
      let parames = {
        sn: store.getters.sn,
        days: number,
      };
      let options = {
        days: number,
      };
      if (store.getters.sn != -1) {
        this.$store.dispatch("get_soc_chart_data", parames);
      } else {
        this.$store.dispatch("get_Soc_chart_data_overview", options);
      }
    },
    handleSelectionM(options) {
      const { m2, m4, m5 } = options;
      let number = this.selectedOptionM === '1' ? 7 : 30;
      let parames = {
        sn: store.getters.sn,
        m2: m2,
        m4: m4,
        m5: m5,
        days: number,
      };
      if (store.getters.sn != -1) {
        this.$store.dispatch("get_apex_chart_data", parames);

      } else {
        this.$store.dispatch("get_apex_chart_data_overview", parames);

      }



    },
    handleSelectionMM(options) {
      const { m2, m4, m5 } = options;
      let number = this.selectedOptionMM === '1' ? 7 : 30;
      let parames = {
        sn: store.getters.sn,
        m2: m2,
        m4: m4,
        m5: m5,
        days: number,
      };
      if (store.getters.sn != -1) {
        this.$store.dispatch("get_apex_chart_data", parames);

      } else {
        this.$store.dispatch("get_apex_chart_data_overview", parames);

      }


    },
    async sendParamsAndGetData() {
      // this.isLoading = true;
      try {
        await store.dispatch("get_current_data");
        await store.dispatch(
          "get_selected_data",
          this.$store.getters.chartSelector
        );
      } catch (error) {
        console.log("error", error);
      }

      // this.isLoading = false;
    },
    async copyToClipboard() {
      try {
        const valueToCopy = this.$store.getters.sn.toString();
        await navigator.clipboard.writeText(valueToCopy);
        // alert('Copied to clipboard');
        this.$notify({
          type: 'info',
          message: `Copied`,
          horizontalAlign: 'center',
          timeout: 600,
          showClose: false,
          style: { width: 'auto' }
        });
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },

  },
  mounted() {
    this.updateSvgVisibility();
    // if (!localStorage.getItem('alertShown')) {
    //   alert('We\'re updating the SAX-Power Kunden portal on March 25, 2024. The site will be unavailable during the update. Thanks for your patience!');
    //   localStorage.setItem('alertShown', 'true');
    // }
    // alert('We\'re updating the SAX-Power Kunden portal on March 25, 2024. The site will be unavailable during the update. Thanks for your patience!');

  },
  beforeDestroy() {
    clearInterval(this.dataInterval);



  },
};
</script>
<style>
select.rounded {
  /* Resetting styles */


  background-color: #27293D;

  color: white;
  /* Add any additional default styles if needed */
}

.last-style {
  background: #27293D;

}

.last-style option {
  color: rgb(231, 12, 12);

}


/* For PC (Desktop) */
@media screen and (min-width: 1024px) {
  .letter {
    margin-right: 5px;
    padding-left: 57px;
  }

  .phase_text {
    height: 0px;
  }

  .butt {
    width: 70px;
    padding-right: 10px;
  }
}

/* For iPad (Tablet) */
@media screen and (min-width: 378px) and (max-width: 5555px) {
  .SN-btn{
    width: 10rem !important;
    height: 2rem !important;
  }
  .logo_sax_login{
    min-width: 10rem !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .butt {
    font-size: 18px;
    width: 12px;
    height: 19px;
    margin: 3px;
    padding-left: 7px;
    margin-right: 20px;
  }

  .overview {
    font-size: auto;
    height: 15px;
    margin: 5px;
    margin-top: 3px;
    margin-bottom: 7px;
  }

  .phase_text {
    font-size: 0px;
    width: 0px;
    height: 0px;
  }
}

/* For Normal Phones */
@media screen and (max-width: 767px) {
  .lastM {
    margin-top: 30px;
  }

  .phases {
    padding-top: 5%;


  }

  .phases_container {
    display: flex;
    justify-content: center;
  }

  .transparent-card {
    background-color: rgba(219, 18, 18, 0.5);
    /* Adjust the alpha value (0-1) for desired transparency */
  }

  .centered-div {
    width: 50%;
    /* Adjust the width as needed */
    margin: 0 auto;
    /* This centers the div horizontally */
  }

  .selector-bg {
    background-color: #27293D !important;
  }

  .selector-t {
    color: #fff !important;
  }

}
</style>