<template>
  <div>
    <!-- <h5 class="info-text">{{$t('update.step2header')}}</h5> -->
    <div class="row justify-content-center">
      <div class="col-lg-10 text-center">
        {{$t('update.step2description')}}
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
    
  },
  data() {
    return {
     
    };
  },
  methods: {
   
    validate() {
      this.$emit('on-validated', true, this.model);
      return Promise.resolve(true);
    }
  }
};
</script>
<style></style>
